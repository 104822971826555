.root {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(var(--spacing-unit) * 2);
}

.icon {
  height: 30px;
  fill: var(--secondary-text-color);
  color: var(--secondary-text-color);
}
.icon:hover {
  cursor: pointer;
}
