.root {
  background-color: var(--footer-background);
  padding: calc(var(--spacing-unit) * 2) 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-unit) * 2);
}

.content .separator {
  margin-top: calc(var(--spacing-unit) * 2);
  margin-bottom: var(--spacing-unit);
  border-top: 1px solid var(--grey-800);
}
@media (max-width: 960px) {
  .content .separator {
    margin-top: 0;
  }
}

.content .copyright {
  display: block;
  text-align: center;
  color: var(--secondary-text-color);
}
