.root {
  padding: var(--spacing-unit) calc(var(--spacing-unit) * 2);
  background-color: var(--grey-100);
  display: flex;
  justify-content: space-between;
  color: var(--grey-900);
  gap: var(--spacing-unit);
}

.megaphone,
.close {
  width: 17px;
}

.content {
  flex: 1;
  line-height: 1.2;
}

.close {
  cursor: pointer;
}
