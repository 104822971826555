.root {
  width: 100%;
  background: var(--header-background);
  box-shadow: 0 2px 3px var(--shadow-color);
  box-sizing: border-box;
  position: sticky;
  top: 0;
}

.separator {
  border-bottom: 1px solid var(--grey-800);
}
