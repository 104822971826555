:root {
  --mobile-min-width: 320px;
  --mobile-max-width: 480px;
  --xs-min-width: 481px;
  --xs-max-width: 768px;
  --sm-min-width: 769px;
  --sm-max-width: 960px;
  --md-min-width: 961px;
  --md-max-width: 1024px;
  --lg-min-width: 1025px;
  --lg-max-width: 1920px;
  --xl-min-width: 1921px;
}

/* templates */

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  /* CSS */
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  /* CSS */
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  /* CSS */
}

/* helpers */

/* mobile */
.on-mobile {
  display: none;
}

@media (max-width: 480px) {
  .on-mobile {
    display: initial;
  }

  .not-on-mobile {
    display: none;
  }
}

/* tablet */

.on-tablet {
  display: none;
}

@media (max-width: 768px) {
  .on-tablet {
    display: initial;
  }

  .not-on-tablet {
    display: none;
  }
}
