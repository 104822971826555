@import-normalize;
@import "./fonts";
@import "./palette";
@import "./breakpoints";
@import "./theme";

html,
body,
#root,
main {
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
