.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: calc(var(--spacing-unit) * 1.5);
}
@media (max-width: 960px) {
  .root {
    justify-content: center;
  }
}
