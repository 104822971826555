.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: calc(var(--spacing-unit) * 3);
  margin: var(--spacing-unit) 0 calc(var(--spacing-unit) * 3);
}

.logo {
  height: 25px;
}
.logo:hover {
  cursor: pointer;
}

.categories {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: calc(var(--spacing-unit) * 3);
}

.brandContainer {
  text-align: center;
}

.brand {
  height: 30px;
}
.brand:hover {
  cursor: pointer;
}

.bag {
  position: relative;
}

.bagLength {
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--danger-color);
  bottom: 2px;
  right: 0;
}

.brandContainer,
.links {
  flex-basis: 110px;
}

.links {
  text-align: right;
}

@media (max-width: 768px) {
  .header {
    flex-wrap: wrap;
  }

  .categories {
    order: 2;
    flex-basis: 100%;
  }
}

@media (max-width: 480px) {
  .categories {
    justify-content: space-between;
  }
}
