:root {
  --black: #000000;
  --grey-900: #151517;
  --grey-800: #3b3b3b;
  --grey-700: #515151;
  --grey-600: #626262;
  --grey-500: #7e7e7e;
  --grey-400: #9e9e9e;
  --grey-300: #b1b1b1;
  --grey-200: #cfcfcf;
  --grey-100: #e1e1e1;
  --grey-50: #f7f7f7;

  --red: #f64747;

  --light-blue: #647ff7;
  --blue: #2d55ff;
  --dark-blue: #1237d1;

  /* Primary / Neutrals
  --blue-grey-050: #f0f4f8;
  --blue-grey-100: #d9e2ec;
  --blue-grey-200: #bcccdc;
  --blue-grey-300: #9fb3c8;
  --blue-grey-400: #829ab1;
  --blue-grey-500: #627d98;
  --blue-grey-600: #486581;
  --blue-grey-700: #334e68;
  --blue-grey-800: #243b53;
  --blue-grey-900: #102a43;

  Supporting
  --light-blue-vivid-050: #e3f8ff;
  --light-blue-vivid-100: #b3ecff;
  --light-blue-vivid-200: #81defd;
  --light-blue-vivid-300: #5ed0fa;
  --light-blue-vivid-400: #40c3f7;
  --light-blue-vivid-500: #2bb0ed;
  --light-blue-vivid-600: #1992d4;
  --light-blue-vivid-700: #127fbf;
  --light-blue-vivid-800: #0b69a3;
  --light-blue-vivid-900: #035388;

  --cyan-050: #e0fcff;
  --cyan-100: #bef8fd;
  --cyan-200: #87eaf2;
  --cyan-300: #54d1db;
  --cyan-400: #38bec9;
  --cyan-500: #2cb1bc;
  --cyan-600: #14919b;
  --cyan-700: #0e7c86;
  --cyan-800: #0a6c74;
  --cyan-900: #044e54;

  --pink-vivid-050: #ffe3ec;
  --pink-vivid-100: #ffb8d2;
  --pink-vivid-200: #ff8cba;
  --pink-vivid-300: #f364a2;
  --pink-vivid-400: #e8368f;
  --pink-vivid-500: #da127d;
  --pink-vivid-600: #bc0a6f;
  --pink-vivid-700: #a30664;
  --pink-vivid-800: #870557;
  --pink-vivid-900: #620042;

  --red-vivid-050: #ffe3e3;
  --red-vivid-100: #ffbdbd;
  --red-vivid-200: #ff9b9b;
  --red-vivid-300: #f86a6a;
  --red-vivid-400: #ef4e4e;
  --red-vivid-500: #e12d39;
  --red-vivid-600: #cf1124;
  --red-vivid-700: #ab091e;
  --red-vivid-800: #8a041a;
  --red-vivid-900: #610316;

  --yellow-vivid-050: #fffbea;
  --yellow-vivid-100: #fff3c4;
  --yellow-vivid-200: #fce588;
  --yellow-vivid-300: #fadb5f;
  --yellow-vivid-400: #f7c948;
  --yellow-vivid-500: #f0b429;
  --yellow-vivid-600: #de911d;
  --yellow-vivid-700: #cb6e17;
  --yellow-vivid-800: #b44d12;
  --yellow-vivid-900: #8d2b0b;

  --teal-050: #effcf6;
  --teal-100: #c6f7e2;
  --teal-200: #8eedc7;
  --teal-300: #65d6ad;
  --teal-400: #3ebd93;
  --teal-500: #27ab83;
  --teal-600: #199473;
  --teal-700: #147d64;
  --teal-800: #0c6b58;
  --teal-900: #014d40; */
}
