/* Graphik */
/* @font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikThin.otf");
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikThinItalic.otf");
  font-display: swap;
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikExtralight.otf");
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikExtralightItalic.otf");
  font-display: swap;
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikLight.otf");
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikLightItalic.otf");
  font-display: swap;
  font-weight: 300;
  font-style: italic;
} */

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikRegular.otf");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikRegularItalic.otf");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikMedium.otf");
  font-display: swap;
  font-weight: 500;
}

/* @font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikMediumItalic.otf");
  font-display: swap;
  font-weight: 500;
  font-style: italic;
} */

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikSemibold.otf");
  font-display: swap;
  font-weight: 600;
}

/* @font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikSemiboldItalic.otf");
  font-display: swap;
  font-weight: 600;
  font-style: italic;
} */

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikBold.otf");
  font-display: swap;
  font-weight: 700;
}

/* @font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikBoldItalic.otf");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
} */

/* @font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikBlack.otf");
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikBlackItalic.otf");
  font-display: swap;
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Graphik;
  src: url("../fonts/graphik/GraphikSuper.otf");
  font-display: swap;
  font-weight: 900;
} */
