.root {
  display: block;
  padding: var(--content-vertical-spacing) var(--content-horizontal-spacing);
  width: 100%;
  box-sizing: border-box;
}
.root:not(.fullwidth) {
  max-width: var(--content-default-max-width);
  margin-left: auto;
  margin-right: auto;
}
.root.small {
  max-width: var(--small-content-max-width);
}
.root.noVerticalSpacing {
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 480px) {
  .root {
    max-width: unset;
    width: 100%;
  }

  .root.noHorizontalSpacingOnMobile {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .root.noHorizontalSpacingOnTablet {
    padding-left: 0;
    padding-right: 0;
  }
}
